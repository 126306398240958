import components from '../../common/_components';
import { _sendRequest, _serialize } from '../../common/_ajax';
import { _getData } from '../../common/_core';
import { _off, _on } from '../../common/_events';
import Tooltip from '../../components.theme/tooltip/tooltip';
import './wysiwyg-newsletter.scss';

const blockName = 'wysiwyg-newsletter';

components.add(`js-${blockName}__form`, (formEl) => {
    const inputEl = formEl.querySelector(`.js-${blockName}__input`);
    const buttonEl = formEl.querySelector(`.js-${blockName}__button`);

    let tooltip;

    function hideTooltip() {
        if (tooltip) {
            tooltip.hide();
        }
    }

    _on(inputEl, 'input', () => {
        const isValid = inputEl.checkValidity ? inputEl.checkValidity() : inputEl.value.length > 0;

        hideTooltip();
        buttonEl.disabled = !isValid;
    });

    _on(formEl, 'submit', (e) => {
        tooltip = new Tooltip(inputEl, {
            availablePositions: ['top-center'],
        });

        e.preventDefault();
        tooltip.hide();

        if (!buttonEl.disabled) {
            const formData = _serialize(formEl);

            inputEl.disabled = true;
            buttonEl.disabled = true;

            _sendRequest({
                method: 'POST',
                url: _getData(formEl, 'ajaxUrl'),
                data: formData,
                success: (responseJSON) => {
                    inputEl.placeholder = _getData(formEl, 'messageSuccess');
                    inputEl.value = '';
                },
                error: (responseJSON) => {
                    tooltip.show(_getData(formEl, 'messageFail'));
                    _on(window, 'scroll', hideTooltip, { once: true });

                    inputEl.disabled = false;
                    buttonEl.disabled = false;
                },
            });
        }

        e.preventDefault();
    });
});
